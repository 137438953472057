
import uiMixin from '../mixins/ui';

export default {
  name: 'InterestsView',
  mixins: [
    uiMixin,
  ],
  computed: {
    interests() {
      const interests = [...this.$store.state.interests.all];

      return interests.map(i => ({
        ...i,
        value: this.getValuePotentialTotalForInterest(i.id),
      }));
    },
  },
  async mounted() {
    if (!this.$store.getters.hasInterests) {
      this.$dbg('action-edit')('mounted', 'addDefaultItems');
      await this.$store.dispatch('interests/addDefaultItems');
      this.uiNotify('Added default interests', {
        severity: 'success',
        logMessage: true,
      });
    }
  },
  methods: {
    getValuePotentialTotalForInterest(id) {
      return this.$store.getters['actionsUser/getValuePotentialForInterest'](id);
    },
    handleInterestValueSelect(id) {
      const actions = this.$store.state.actionsUser.all.filter(i => (i.interest.includes(id) && i.status !== 'Done'));

      if (actions.length > 0) {
        this.uiShowActionSelector(actions.map(i => i.id));
      } else {
        this.uiShowActionSelector([]);
      }
    },
    getColorForItem(id) {
      return this.$store.getters['interests/getColorForItem']({ id });
    },
    getStyleForItem(id) {
      const style = {};

      style.boxShadow = `inset 33px 0px 37px -27px ${this.getColorForItem(id)}`;

      return style;
    },
  },
};
